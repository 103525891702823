import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import BlogRoll from '../components/BlogRoll'
import Breadcrumbs from '../components/Breadcrumbs'
import Content, { HTMLContent } from '../components/Content'
import EventsTable from '../components/EventsTable'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import TitleImage from '../components/TitleImage'

export const EventsPageTemplate = ({ 
  title,
  image,
  heading,
  content,
  contentComponent,
  upcomingEventsDescription,
  pastEventsDescription,
  location
}) => {

  const [upcomingEventsCount, setUpcomingEventsCount] = useState(0);

  const PageContent = contentComponent || Content

  return (
    <React.Fragment>
      {!!image ? (
        <TitleImage title={title} image={image.childImageSharp.fluid} />
      ) : null }
      <section className="section section--gradient">
        <div className="container">
          <Breadcrumbs pathname={location?.pathname}/>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <div className="content">
                  {!!heading ? (<PageTitle title={heading}/>) : null }
                  <PageContent className="content" content={content} />
                </div>
                <div className="content">
                  <h3 className="title is-size-4 has-text-weight-semibold is-bold-light">Upcoming Events</h3>
                  <p>{upcomingEventsDescription}</p>
                  {/* <p>{upcomingEventsCount > 0 ? upcomingEventsDescription : "No upcoming events to show."}</p> */}
                  <BlogRoll displayTags={["upcoming-events"]} handlePostsCount={setUpcomingEventsCount}/>
                  <br />
                </div>
                <div className="content">
                  <h3 className="title is-size-4 has-text-weight-semibold is-bold-light">Past Events</h3>
                  <p>See a summary of our previous fundraising challenges: </p>
                  <EventsTable />
                  <p className="mt-2 mb-2">{pastEventsDescription}</p>
                  <BlogRoll displayTags={["past-events"]}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

EventsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  upcomingEventsDescription: PropTypes.string,
  pastEventsDescription: PropTypes.string,
}

const EventsPage = ({ data, location }) => {
  const { markdownRemark: pageData } = data

  return (
    <Layout>
      <EventsPageTemplate
        title={pageData.frontmatter.title}
        image={pageData.frontmatter.image}
        heading={pageData.frontmatter.heading}
        content={pageData.html}
        contentComponent={HTMLContent}
        upcomingEventsDescription={pageData.frontmatter.upcomingEventsDescription}
        pastEventsDescription={pageData.frontmatter.pastEventsDescription}
        location={location}
      />
    </Layout>
  )
}

EventsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default EventsPage

export const eventsPageQuery = graphql`
  query EventsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        upcomingEventsDescription
        pastEventsDescription
      }
    }
  }
`
