import React from 'react';

const pastEventsArray = [
  { date: new Date("1999-06"), event: "Swimathon 1", cause: "Ambulance", fundsRaised: 13187, articleLink: "" },
  { date: new Date("2001-06"), event: "Swimathon 2", cause: "Education Projects", fundsRaised: 19330, articleLink: "" },
  { date: new Date("2003-09"), event: "Swimathon 3", cause: "Education Projects", fundsRaised: 17273, articleLink: "" },
  { date: new Date("2004-07"), event: "Charity Walk 1- River Thames", cause: "Education Projects", fundsRaised: 45000, articleLink: "" },
  { date: new Date("2005-05"), event: "Charity Walk 2- River Thames", cause: "Education Projects", fundsRaised: 24597, articleLink: "" },
  { date: new Date("2006-07"), event: "Swimathon 4", cause: "Education Projects", fundsRaised: 13082, articleLink: "" },
  { date: new Date("2007-06"), event: "Charity Walk 3 – Chess Valley", cause: "Education Projects", fundsRaised: 20000, articleLink: "" },
  { date: new Date("2009-07"), event: "Charity Walk 4 – Chess Valley", cause: "Education Projects", fundsRaised: 17250, articleLink: "" },
  { date: new Date("2011-08"), event: "Mt. Kilimanjaro Challenge 2011", cause: "Health Projects", fundsRaised: 90518, articleLink: "kilimanjaro-challenge-2011" },
  { date: new Date("2012-08"), event: "Mt. Kilimanjaro Challenge 2012", cause: "Health Projects", fundsRaised: 39001, articleLink: "kilimanjaro-challenge-2012" },
  { date: new Date("2014-08"), event: "Mt. Kilimanjaro Challenge 2014", cause: "Education Projects", fundsRaised: 4000, articleLink: "kilimanjaro-challenge-2014" },
  { date: new Date("2015-06"), event: "Charity Walk 5 - River Thames Windsor", cause: "Education Projects", fundsRaised: 13087, articleLink: "" },
  { date: new Date("2016-06"), event: "Charity Walk 6 - River Thames Windsor", cause: "Education Projects", fundsRaised: 8844, articleLink: "" },
  { date: new Date("2017-09"), event: "Charity Walk 7 - Chess Valley", cause: "Education Projects", fundsRaised: 22867, articleLink: "" },

  { date: new Date("2005-02"), event: "Mehfil - Maheshbhai &  Nitu  Gadhvi", cause: "General awareness", fundsRaised: 1583, articleLink: "" },
  { date: new Date("2006-03"), event: "Musical  evening - Mehboob Nadeem", cause: "General awareness", fundsRaised: 1944, articleLink: "" },
  { date: new Date("2007-05"), event: "Musical evening - Gurdev  Singh Namdhari", cause: "General awareness", fundsRaised: 351, articleLink: "" },
  { date: new Date("2012-12"), event: "Musical evening - SVP group", cause: "General awareness", fundsRaised: 126, articleLink: "" },
  { date: new Date("2013-12"), event: "Music evening - Bhavik Haria", cause: "General awareness", fundsRaised: 632, articleLink: "" },
  { date: new Date("2014-09"), event: "Music evening - Bhavik Haria", cause: "General awareness", fundsRaised: 250, articleLink: "" },
  { date: new Date("2015-03"), event: "Play - Chorna Hathma Chavi", cause: "General awareness", fundsRaised: 1773, articleLink: "" },
  { date: new Date("2015-11"), event: "Music evening - Mehfillers", cause: "General awareness", fundsRaised: 2064, articleLink: "" },
  { date: new Date("2016-04"), event: "Play - Bei Bei wife pan ekaj life", cause: "General awareness", fundsRaised: 2508, articleLink: "" },
  { date: new Date("2016-11"), event: "Music  evening - Narayanan", cause: "General awareness", fundsRaised: 1427, articleLink: "" },
  { date: new Date("2017-05"), event: "Play - lagna ni viadhi, divorce ni  upadhi", cause: "General awareness", fundsRaised: 1441, articleLink: "" },
  { date: new Date("2018-11"), event: "Music evening - Kaushik Khajuria", cause: "General awareness", fundsRaised: 1360, articleLink: "" },
  { date: new Date("2019-10"), event: "Music evening - Ashit & Hema Desai", cause: "General awareness", fundsRaised: 2255, articleLink: "" },
  { date: new Date("2019-11"), event: "Music evening - Shahid Abbas Khan", cause: "General awareness", fundsRaised: 2893, articleLink: "" },
];



const EventsTable = () => {
  return (
    <table className="table is-striped events-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Event</th>
          <th>Cause</th>
          <th>Funds Raised</th>
        </tr>
      </thead>
      <tbody>
        {pastEventsArray.sort((a,b) => a.date - b.date).map((pastEvent) => {
          const eventRow = !!pastEvent.articleLink ? (<th><a href={`/get-involved/events/${pastEvent.articleLink}`}>{pastEvent.event}</a></th>) : (<th>{pastEvent.event}</th>);
          return (
            <tr key={pastEvent.date.toDateString()}>
              <td>{pastEvent.date.toLocaleDateString("en-UK", { year: 'numeric', month: 'short' })}</td>
              {eventRow}
              <td>{pastEvent.cause}</td>
              <td>{new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(pastEvent.fundsRaised)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  )
}

export default EventsTable